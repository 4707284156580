body {
    font-size: .875rem;
}
.map {
    height: 60vh;
    width: 95vh;
}

.feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}


/*
 * Sidebar
 */

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 52; /* Behind the navbar */
    padding: 60px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
    position: relative;
    top: 0;
    left: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.sidebar .nav-link {
    font-weight: 500;
    color: #333;
}

.sidebar .nav-link .feather {
    margin-right: 4px;
    color: #999;
}

.sidebar .nav-link.active {
    color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
    color: inherit;
}

.sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
}

/*
 * Content
 */

[role="main"] {
    padding-top: 60px; /* Space for fixed navbar */
}

@media (min-width: 768px) {
    [role="main"] {
        padding-top: 60px; /* Space for fixed navbar */
    }
    .layer-switcher.shown {
        max-height: 50vh;
    }
}

/*
 * Navbar
 */

.form-inline{
    padding-left: 10px;
    padding-right: 10px;
}
.btn-outline-primary{
    color: #00839a;
    border-color: #00839a;
}
.btn-group{
    padding-left: 10px;
    padding-right: 10px;
}
h6 {
    display:inline-block;
}
.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
    white-space: normal;
}
.tabulator-tableHolder {
    max-width: 100% !important;
}
.node circle {
    fill: #fff;
    stroke: steelblue;
    stroke-width: 3px;
}

.node text {
    font: 12px sans-serif;
}

.link {
    fill: none;
    stroke: #ccc;
    stroke-width: 2px;
}
img {
    max-width: 100%;
    max-height: 100%;
}
.table-header {
    background-color: #878b93;
}
form .error {
    color: #ff0000;
}
.requiredForm{
    color: red;
}


