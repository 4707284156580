body {
  font-size: .875rem;
}

.map {
  width: 95vh;
  height: 60vh;
}

.feather {
  vertical-align: text-bottom;
  width: 16px;
  height: 16px;
}

.sidebar {
  z-index: 52;
  padding: 60px 0 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: inset -1px 0 #0000001a;
}

.sidebar-sticky {
  height: calc(100vh - 48px);
  padding-top: .5rem;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden auto;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .sidebar-sticky {
    position: sticky;
  }
}

input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sidebar .nav-link {
  color: #333;
  font-weight: 500;
}

.sidebar .nav-link .feather {
  color: #999;
  margin-right: 4px;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather, .sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  text-transform: uppercase;
  font-size: .75rem;
}

[role="main"] {
  padding-top: 60px;
}

@media (width >= 768px) {
  [role="main"] {
    padding-top: 60px;
  }

  .layer-switcher.shown {
    max-height: 50vh;
  }
}

.form-inline {
  padding-left: 10px;
  padding-right: 10px;
}

.btn-outline-primary {
  color: #00839a;
  border-color: #00839a;
}

.btn-group {
  padding-left: 10px;
  padding-right: 10px;
}

h6 {
  display: inline-block;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
  white-space: normal;
}

.tabulator-tableHolder {
  max-width: 100% !important;
}

.node circle {
  fill: #fff;
  stroke: #4682b4;
  stroke-width: 3px;
}

.node text {
  font: 12px sans-serif;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 2px;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.table-header {
  background-color: #878b93;
}

form .error, .requiredForm {
  color: red;
}

/*# sourceMappingURL=index.b6feedb6.css.map */
